/**
 * Refresh if our app version has changed
 */
const getVersionFromHtml = function(html) {
    try {
        const meta = html.querySelector('meta[name="X-App-Version"]');
        return meta.getAttribute('content');
    } catch(e) {
        up.log.warn('app:getVersionFromHtml', e.message);
        return null;
    }
};

const currentVersion = getVersionFromHtml(document);
console.log('app:currentVersion', currentVersion);

if(currentVersion) {
  // reload if new build version detected
  up.on('up:fragment:loaded', (event) => {
      try {
          const parser = new DOMParser();
          const parsedHtml = parser.parseFromString(event.response.text, 'text/html');
          const responseVersion = getVersionFromHtml(parsedHtml);
          up.log.puts('app:responseVersion', responseVersion);
          if(responseVersion && responseVersion !== currentVersion) {
              throw new Error('New version detected: ' + responseVersion + ' !== ' + currentVersion);
          }
      } catch(e) {
          up.log.warn('app:versionMismatch', e.message);
          up.cache.clear();
          event.preventDefault();
          return event.request.loadPage();
      };
  });
}

/**
 * Videos break in Safari when they are injected into the DOM.
 * - Only binds event if Safari browser
 * - `initialLoad` flag avoids our code running on initial page render
 * - Query for all video elements inside fragment
 * - Overwriting its `outerHTML` forces Safari to re-render the video.
 */
const isSafari = () => {
  const ua = navigator.userAgent;
  console.log(`User Agent: ${ua}`);
  return ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('CriOS') && !ua.includes('FxiOS');
};

if (isSafari()) {
  let initialLoad = true;
  up.on('up:fragment:inserted', (event, fragment) => {
    if (initialLoad) {
      initialLoad = false;
      return;
    }

    const videos = fragment.querySelectorAll('video');
    if (videos.length === 0) return;

    videos.forEach((video) => {
      video.outerHTML = video.outerHTML;
    });
  });
}


/**
 * Smooth scroll to target?
 */
up.on('up:fragment:inserted', function(event, fragment) {
  if(fragment.hasAttribute('data-scroll-to-target')) {
    let selector = fragment.getAttribute('data-scroll-to-target');
    let behavior = fragment.getAttribute('data-scroll-behavior') || 'smooth';
    let target = selector ? document.querySelector(selector) : fragment;
    if( ! target) {
      return;
    }
    window.scrollTo({
      behavior: behavior,
      top: target.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top
    });
  }
});

/**
 * Open subscribe & enquire links into modal
 */
document.addEventListener('click', function(event) {

  // Check if the click is on a link
  const link = event.target.closest('a');
  if (!link) return;

  // Get the href of the link
  const href = link.getAttribute('href');
  if (!href) return;

  // Ensure it's a local URL
  const url = new URL(href, window.location.origin);
  if (url.hostname !== window.location.hostname) return;

  // Check if the link's href matches `/subscribe` or '/enquire
  let isSubscribe = /^\/subscribe$/.test(url.pathname);
  let isEnquiry = /^\/enquire\//.test(url.pathname);

  if (isSubscribe || isEnquiry) {

    event.preventDefault();

    let target;

    if(isSubscribe) {
      target = '[up-id=subscribe]';
    }
    else if(isEnquiry) {
      target = '[up-id=enquiry]';
    }

    up.navigate({
      url: href,
      layer: 'new',
      size: 'grow',
      history: false,
      dismissable: 'button',
      target: target,
    });

    return;
  }

  // Check if the link's href matches `/enquire` or `/enquire/*`
  const urlPattern = /^\/enquire(\/[^\/]*)?$/;
  if (urlPattern.test(url.pathname)) {

    // Allow user to open enquire links in new window
    if (event.ctrlKey || event.metaKey) return;

    event.preventDefault();

    up.navigate({
      url: href,
      layer: 'new',
      size: 'grow',
      history: false,
      dismissable: 'button',
      target: '[up-id=enquiry]',
    });
  }
});
