import { Formie } from '@js/vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';
window.Formie = Formie;

/**
 * Initialize a formie form
 *
 * Usage:
 *
 * <div x-data="formie('form', 'artwork', { submit: { ...dataLayerVariables } })">
 *   {{ craft.formie.renderForm('form-handle') }}
 * </div>
 *
 * @param formSelector
 * @returns {{init(): void}}
 */
export function formie(formSelector, name = null, dataLayerEvents = {}) {
  let formEl = null;
  let formieInstance = null;

  return {
    init() {
      formEl = this.$root.querySelector(formSelector);
      if (!formEl) {
        return;
      }
      formieInstance = new Formie();
      formieInstance.initForm(formEl);

      if(dataLayerEvents.submit) {
        formEl.addEventListener('submit', () => {
          let defaultData = { event: 'form_submission' };
          const data = { ...defaultData, ...dataLayerEvents.submit };
          window.dataLayer = window.dataLayer || [];
          dataLayer.push(data);
        });
      }

      setTimeout(() => {
        formEl.querySelector('input:not([type=hidden])').focus();

        // Phone country field a11y
        let phoneCountry = formEl.querySelector('.iti__selected-flag');
        if (!phoneCountry) return;
        phoneCountry.id = 'phone-country';
        if(name) phoneCountry.id = `${phoneCountry.id}-${name}`;
        this.addPhoneCountryLabel(phoneCountry);
      }, 300);
    },

    addPhoneCountryLabel(field) {
      let label = document.createElement('label');
      label.htmlFor = field.id;
      label.classList.add('sr-only');
      label.id = `${field.id}-label`;
      let labelText = document.createTextNode('Select country code');
      label.appendChild(labelText);

      field.insertAdjacentElement('beforebegin', label);
    }
  };
}
