/**
 * Product component
 *
 * Usage:
 *
 *   <div x-data="product('{{ product.shopifyId }}', '{{ product.url }}')">
 *     ...
 *     <button x-on:click="addLineItem('{{ product.getDefaultVariant().id }}')">Add to Cart</button>
 *   </div>
 *
 * @param id
 * @param url
 * @returns {{init(): Promise<void>, product: null, addLineItem(*, number=): Promise<void>, id, url: string}}
 */
export default function(id, url = '', currency = null) {
  let base = 'gid://shopify/ProductVariant/';

  return {
    /**
     * ID of product
     */
    id: id,

    /**
     * Memoized copy of product
     */
    product: null,

    /**
     * Url to product page in Craft
     */
    url: url,

    /**
     * Internal flag indicating that an update is underway
     */
    updating: false,

    /**
     * Track the view_item event for the current variant
     * @param variantId
     */
    track(variantId) {
      window.dataLayer = window.dataLayer || [];
      const data = { event: 'view_item', currency: currency, variant_id: variantId };
      dataLayer.push(data);
    },

    /**
     * Get the variant ID from the selected form option
     * @param form
     * @returns {number}
     */
    getSelectedVariant(form) {
      const options = Array.from(form.querySelectorAll('option'));
      const selected = options.find(o => o.selected);
      const id = Number(selected.dataset.id);
      return id;
    },

    /**
     * Add variant to cart
     * Supports passing id or admin_graphql_api_id
     *
     * @param variantId
     * @param qty
     * @returns {Promise<void>}
     */
    async addLineItem(variantId, qty = 1) {
      this.updating = true;
      let gid = variantId.startsWith(base) ? variantId : base + variantId;
      await Alpine.store('cart').addLineItem(
        gid,
        qty,
        [
          {
            key: '_productUrl',
            value : this.url,
          }
        ]
      );
      console.log('Added ' + variantId);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({ event: 'add_to_cart', variant_id: variantId, currency: currency });
      this.updating = false;
    }
  };
}
